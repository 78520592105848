import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  downloadFileDepositReport,
  downloadFileUserReport,
  downloadFileWithdrawAffiliateListReport,
  downloadFileWithdrawReport,
  generateUserDepositReport,
  getUserDepositReportList,
} from 'containers/Report/apiHelper';

export const fetchReportUser = createAsyncThunk('report/user', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(downloadFileUserReport(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchReportDeposit = createAsyncThunk('report/deposit', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(downloadFileDepositReport(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchReportWithdraw = createAsyncThunk('report/withdraw', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(downloadFileWithdrawReport(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchReportWithdrawAffiliate = createAsyncThunk('report/withdraw-affiliate', async arg => {
  const response = await api(downloadFileWithdrawAffiliateListReport(arg));
  return response.data;
});

export const fetchGenerateUserDepositReport = createAsyncThunk('report/user/deposit', async arg => {
  const response = await api(generateUserDepositReport(arg));
  return response.data;
});
export const fetchGetUserDepositReport = createAsyncThunk('report/get/user/deposit', async arg => {
  const response = await api(getUserDepositReportList());
  return response.data;
});

const extraReducers = {
  [fetchReportUser.pending]: (state, action) => {
    state.users = {...action.payload, loading: true};
  },
  [fetchReportUser.fulfilled]: (state, action) => {
    state.users = {...action.payload, loading: false};
  },
  [fetchReportDeposit.pending]: (state, action) => {
    state.deposits = {...action.payload, loading: true};
  },
  [fetchReportDeposit.fulfilled]: (state, action) => {
    state.deposits = {...action.payload, loading: false};
  },
  [fetchReportWithdraw.pending]: (state, action) => {
    state.withdraws = {...action.payload, loading: true};
  },
  [fetchReportWithdraw.fulfilled]: (state, action) => {
    state.withdraws = {...action.payload, loading: false};
  },
  [fetchReportWithdrawAffiliate.pending]: (state, action) => {
    state.withdrawsAffiliate[action.meta.arg?.type] = {...action.payload, loading: true};
  },
  [fetchReportWithdrawAffiliate.fulfilled]: (state, action) => {
    state.withdrawsAffiliate[action.meta.arg?.type] = {
      ...action.payload,
      loading: false,
    };
  },
  [fetchGetUserDepositReport.pending]: (state, action) => {
    state.userDeposit = {...action.payload, loading: true};
  },
  [fetchGetUserDepositReport.fulfilled]: (state, action) => {
    state.userDeposit = {...action.payload, loading: false};
  },
};

const initialState = {
  users: {},
  deposits: {},
  withdraws: {},
  withdrawsAffiliate: {
    af: {},
    wl: {},
  },
  userDeposit: {},
};

const name = 'report';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
