export function downloadFileUserReport(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/secure/report/user`,
    data: payload,
    responseType: 'blob',
    cancelToken,
  };
}

export function downloadFileDepositReport(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/secure/report/list/deposit`,
    data: payload,
    responseType: payload?.fileType === 'xlsx' ? 'blob' : 'json',
    cancelToken,
  };
}

export function downloadFileWithdrawReport(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/secure/report/list/withdraw`,
    data: payload,
    responseType: payload?.fileType === 'xlsx' ? 'blob' : 'json',
    cancelToken,
  };
}

export function downloadFileWithdrawAffiliateListReport(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/report/list/withdraw-affiliate`,
    data: payload,
    responseType: payload?.fileType === 'xlsx' ? 'blob' : 'json',
  };
}

export function generateUserDepositReport(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/report/user/deposit/by/time`,
    data: payload,
  };
}

export function getUserDepositReportList() {
  return {
    method: 'GET',
    endpoint: `/secure/report/file/user/deposit/by/time`,
  };
}
